import { version } from '../../../../package.json';

export const envs = {
  version: version,
  apiUrl: import.meta.env.APP_ENV_API_URL,
  graphqlApiUrl: import.meta.env.APP_ENV_GRAPHQL_API_URL,
  s3Url: import.meta.env.APP_ENV_S3_URL,
  sentryDsn: import.meta.env.APP_ENV_SENTRY_DSN,
  xProduct: import.meta.env.APP_ENV_X_PRODUCT,
};
